import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "migrating-to-popper-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#migrating-to-popper-2",
        "aria-label": "migrating to popper 2 permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Migrating to Popper 2`}</h1>
    <p>{`Popper 2 introduces a lot of changes for the better! Here's a guide to help you
migrate to the new version.`}</p>
    <x-ad />
    <h2 {...{
      "id": "1-install-the-new-package",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-install-the-new-package",
        "aria-label": "1 install the new package permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Install the new package`}</h2>
    <p>{`Remove the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`popper.js`}</code>{` package from your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`package.json`}</code>{` or CDN script tag
includes, and install `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@popperjs/core`}</code>{`.`}</p>
    <p>{`Popper has changed its package name to live under the scoped `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`@popperjs`}</code>{`
organization. This is where other packages like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`react-popper`}</code>{` will now live
too.`}</p>
    <h2 {...{
      "id": "2-change-the-import",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-change-the-import",
        "aria-label": "2 change the import permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Change the import`}</h2>
    <h3 {...{
      "id": "modules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modules",
        "aria-label": "modules permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Modules`}</h3>
    <p>{`In Popper 1, it was a class:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` Popper `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'popper.js'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Popper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`In Popper 2, it's now a function:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h3 {...{
      "id": "cdn--umd-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#cdn--umd-version",
        "aria-label": "cdn  umd version permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CDN / `}<code parentName="h3" {...{
        "className": "language-text"
      }}>{`umd`}</code>{` version`}</h3>
    <p>{`This named import lives under the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Popper`}</code>{` namespace object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`Popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`createPopper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "3-change-your-css-attribute-selectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-change-your-css-attribute-selectors",
        "aria-label": "3 change your css attribute selectors permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Change your CSS attribute selectors`}</h2>
    <p>{`In v1, attributes were prefixed with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x-`}</code>{`, for example, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x-placement`}</code>{`. In v2,
these are now prefixed with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-popper-`}</code>{`, which matches the HTML5 spec, and
has its own data namespace to prevent conflicts.`}</p>
    <p>{`Also, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`x-out-of-boundaries`}</code>{` is now `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data-popper-reference-hidden`}</code>{`.`}</p>
    <h2 {...{
      "id": "4-remove-all-css-margins",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-remove-all-css-margins",
        "aria-label": "4 remove all css margins permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Remove all CSS margins`}</h2>
    <p>{`In Popper 2 we no longer consider CSS `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` because of inherent issues with
it. Instead, to apply some padding between the popper and its reference element,
use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`offset`}</code>{` modifier. You also need to remove `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`margin`}</code>{` from your arrows
too; instead use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` option in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`arrow`}</code>{` modifier.`}</p>
    <h2 {...{
      "id": "5-ensure-your-popper-and-arrow-box-size-is-constant-for-all-placements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#5-ensure-your-popper-and-arrow-box-size-is-constant-for-all-placements",
        "aria-label": "5 ensure your popper and arrow box size is constant for all placements permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`5. Ensure your popper and arrow box size is constant for all placements`}</h2>
    <p>{`Your popper and arrow cannot be a different width or height for different
placements; it must be constant in size. This is due to the way the Popper
lifecycle works – which can't know ahead of time (before writing to the DOM)
what the computed CSS will be, which can change the size of the elements. In
Popper 1, this caused all sorts of flicker and jitter issues.`}</p>
    <p>{`We are looking into a way to allow this in the future, but it's currently a
difficult problem to solve efficiently.`}</p>
    <h2 {...{
      "id": "6-add-back-boundary-paddings-if-necessary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#6-add-back-boundary-paddings-if-necessary",
        "aria-label": "6 add back boundary paddings if necessary permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`6. Add back boundary paddings if necessary`}</h2>
    <p>{`In Popper 2, all `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` is now `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`0`}</code>{`. In v1, these were `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`5`}</code>{` for
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`preventOverflow`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flip`}</code>{`. If necessary, you can add these back using the
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`padding`}</code>{` option, which works the same as v1.`}</p>
    <h2 {...{
      "id": "7-change-positionfixed",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#7-change-positionfixed",
        "aria-label": "7 change positionfixed permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`7. Change `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`positionFixed`}</code></h2>
    <p>{`In Popper 2, this is now the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`strategy`}</code>{` option:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  strategy`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'fixed'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "8-update-method-names",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#8-update-method-names",
        "aria-label": "8 update method names permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`8. Update method names`}</h2>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`update()`}</code>{` is now `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`forceUpdate()`}</code>{` (and is sync)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`scheduleUpdate()`}</code>{` is now `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`update()`}</code>{` (and returns a promise)`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`enableEventListeners`}</code>{` / `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`disableEventListeners`}</code>{` are gone; see the
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`eventListeners`}</code>{` modifier. You can use the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`setOptions`}</code>{` method to change the
`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`scroll`}</code>{` and `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`resize`}</code>{` options at will to replicate the original methods'
functionality.`}</li>
    </ul>
    <h2 {...{
      "id": "9-update-callbacks",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#9-update-callbacks",
        "aria-label": "9 update callbacks permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`9. Update callbacks`}</h2>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`onCreate`}</code>{` is now `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`onFirstUpdate`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`onUpdate`}</code>{` is gone; use a custom modifier with an `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`afterWrite`}</code>{` phase`}</li>
    </ul>
    <h2 {...{
      "id": "10-update-modifiers",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#10-update-modifiers",
        "aria-label": "10 update modifiers permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`10. Update modifiers`}</h2>
    <p>{`In Popper 2 this is now an array of objects (modifiers), instead of an object
where each property was the modifier name.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'flip'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      enabled`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`In addition, their options are grouped together in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`options`}</code>{` object:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`createPopper`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`reference`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popper`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  modifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      name`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'flip'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      options`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        padding`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`We cannot list all the changes to them here (there are a lot!); you'll need to
visit each modifier in the menu to learn more about their new behavior.`}</p>
    <p>{`See `}<a parentName="p" {...{
        "href": "../modifiers/"
      }}>{`Modifiers`}</a>{` for more information on writing custom modifiers.`}</p>
    <h2 {...{
      "id": "11-inner-keeptogether-shift-modifiers-are-gone",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#11-inner-keeptogether-shift-modifiers-are-gone",
        "aria-label": "11 inner keeptogether shift modifiers are gone permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`11. `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`inner`}</code>{`, `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`keepTogether`}</code>{`, `}<code parentName="h2" {...{
        "className": "language-text"
      }}>{`shift`}</code>{` modifiers are gone`}</h2>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`inner`}</code>{` can be replicated using the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`offset`}</code>{` modifier with negative values`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`keepTogether`}</code>{` functionality lives in `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`preventOverflow`}</code>{` in `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`tether`}</code>{` options`}</li>
    </ul>
    <h2 {...{
      "id": "12-remove-virtual-element-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#12-remove-virtual-element-properties",
        "aria-label": "12 remove virtual element properties permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`12. Remove virtual element properties`}</h2>
    <p>{`The only property your virtual elements (or Reference Objects in Popper 1) need
is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`getBoundingClientRect`}</code>{`. The other properties are unnecessary now.`}</p>
    <h2 {...{
      "id": "13-transitions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#13-transitions",
        "aria-label": "13 transitions permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`13. Transitions`}</h2>
    <p>{`In Popper 2, the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`computeStyles`}</code>{` modifier has a new option called `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`adaptive`}</code>{`
enabled by default, which breaks CSS transitions. You should set this option to
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`false`}</code>{` if CSS transitions are enabled.`}</p>
    <p>{`Keep in mind, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`adaptive`}</code>{` has the benefit of allowing you to change the height or
width of the popper in most cases without needing to update its position, so
you'll lose this benefit when disabling it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      